<template>
  <div class="user">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.user.users.users.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.user.users.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
      >
        <template v-slot:[`item.office`]="item"> 
          {{ item.item.office.name }}
        </template>
        <template v-slot:[`item.actions`]="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="edit(item.item)"
                class="px-1"
              >
                mdi-account-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
                @click="editPassword(item.item)"
                class="px-1"
              >
                mdi-account-key
              </v-icon>
            </template>
            <span>Actualizar Contraseña</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
                @click="distroy(item.item)"
                class="px-1"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>
    <!-- Button active dialog -->
    <v-btn
      class="mx-2"
      fab
      dark
      bottom
      absolute
      right
      color="secondary"
      @click="open"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <!-- Create or edit user dialog -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ currentUser ? "Editar" : "Crear" }}
          {{ isUpdatePassword ? "contraseña" : "usuario" }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-4">
          <v-form ref="formUser" v-model="validForm" lazy-validation>
            <v-text-field
              v-model="model.name"
              :rules="rules.name"
              label="Nombre"
              required
              v-if="!isUpdatePassword"
            ></v-text-field>

            <v-text-field
              v-model="model.email"
              :rules="rules.email"
              label="Correo"
              required
              v-if="!isUpdatePassword"
            ></v-text-field>

            <v-select
              v-model="model.role"
              :items="['ADMIN', 'SUPERADMIN']"
              :rules="rules.role"
              label="Rol"
              required
              v-if="!isUpdatePassword"
            ></v-select>

            <v-select              
              :items="offices"
              item-text="name"
              item-value="id"
              label="Seleccione Oficina"
              no-data-text="No hay registros..."
              :rules="rules.office"                            
              v-model="model.office_id"
            ></v-select>

            <v-text-field
              v-model="model.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.password"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              hint="Mínimo 8 caracteres"
              :counter="8"
              @click:append="showPassword = !showPassword"
              v-if="showFieldPassword"
            ></v-text-field>

            <v-text-field
              v-model="model.password_confirmation"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.password"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Confirmar contraseña"
              :counter="8"
              @click:append="showPassword = !showPassword"
              v-if="showFieldPassword"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!validForm"
            color="success"
            class="mr-4"
            small
            @click="save"
          >
            Guardar
          </v-btn>

          <v-btn color="error" text class="mr-4" small @click="clear">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" width="500" persistent>
      <v-card>
        <v-card-text class="py-4">
          Seguro que desea eliminar este usuario?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-4"
            @click="distroyConfirmation"
            small
          >
            Confirmar
          </v-btn>

          <v-btn color="error" text class="mr-4" @click="clear" small>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_ALERT } from "@/store/alert";
import { SET_USERS } from "@/store/user/users";
import { SET_OFFICES } from "@/store/offices";
export default {
  name: "Users",
  data: () => ({
    search: "",
    headers: [
      {
        text: "Nombre",
        align: "start",
        filterable: false,
        value: "name",
      },
      { text: "Correo", value: "email" },
      { text: "Rol", value: "role" },
      { text: "Oficiona", value: "office" },
      { text: "Ultima Conexión", value: "connection_last" },
      { text: "Acciones", value: "actions" },
    ],
    pagination: {
      current: 0,
      total: 0,
    },
    dialog: false,
    dialogDelete: false,
    validForm: true,
    currentUser: null,
    model: {
      name: "",
      email: "",
      role: "",
      office_id: "",
      password: "",
      password_confirmation: "",
    },
    rules: {
      name: [(v) => !!v || "EL nombre es requerido"],
      email: [
        (v) => !!v || "El correo es requerido",
        (v) => /.+@.+\..+/.test(v) || "Debe ser un correo valido",
      ],
      role: [(v) => !!v || "El rol es requerido"],
      office: [(v) => !!v || "La oficina es requerida"],
      password: [
        (v) => !!v || "La contraseña es requerida",
        (v) => !v || v.length >= 8 || "Mínimo 8 caracteres",
      ],
    },
    showPassword: false,
    showFieldPassword: true,
    isUpdatePassword: false,
    offices: [],
  }),
  created() {
    this.$store.dispatch("getUsers");
    this.$store.dispatch("getOfficesAll");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_USERS) {
        this.pagination.current = state.user.users.users.current_page;
        this.pagination.total = state.user.users.users.last_page;
      }
      if (mutation.type === SET_OFFICES) {
        this.offices = state.offices.offices;
      }
    });
  },
  watch: {
    dialog: function () {
      if (this.dialog == false) this.clear();
    },
  },
  methods: {
    nextPage() {
      this.$store.dispatch("nextPage", this.pagination.current);
    },
    save() {
      if (!this.$refs.formUser.validate()) return;

      if (this.currentUser) {
        if (this.isUpdatePassword) {
          if (this.validatePassword()) return;
          delete this.model.password_confirmation;
        }

        this.$store.dispatch("updateUser", {
          form: this.model,
          id: this.currentUser.id,
        });
      } else {
        if (this.validatePassword()) return;
        this.$store.dispatch("createUser", this.model);
      }
      this.clear();
    },
    distroy(user) {
      this.currentUser = user;
      this.dialogDelete = true;
    },
    distroyConfirmation() {
      this.$store.dispatch("deleteUser", this.currentUser.id);
      this.clear();
    },
    open() {
      this.dialog = true;
    },
    edit(user) {
      this.currentUser = user;
      this.showFieldPassword = false;
      this.model = {
        name: user.name,
        email: user.email,
        role: user.role,
        office_id: user.office_id,
      };
      this.open();
    },
    editPassword(user) {
      this.currentUser = user;
      this.isUpdatePassword = true;
      this.model = {
        password: "",
        password_confirmation: "",
      };
      this.open();
    },
    clear() {
      this.dialog = false;
      this.dialogDelete = false;
      this.isUpdatePassword = false;
      this.showFieldPassword = true;
      this.model = {
        name: "",
        email: "",
        role: "",
        office_id: "",
        password: "",
        password_confirmation: "",
      };
      this.$refs.formUser.resetValidation();
      this.currentUser = false;
    },
    validatePassword() {
      if (this.model.password !== this.model.password_confirmation) {
        this.$store.commit(SET_ALERT, {
          message: "Las contraseñas no coninciden",
          type: "error",
        });
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.user {
  height: 100%;
}
.v-btn--bottom {
  bottom: 11px !important;
}
</style>
